<template>
  <div class="d-flex d-flex-c d-flex-between flex-1">
    <div class="d-flex d-flex-r d-flex-between d-align-content-start section">
      <chart-wrapper
        label="Seniority"
        chart-type="pie"
        wrapper-type="large f-height"
        :chart-config="audienceSeniority"
      />
      <chart-wrapper
        class="ml-1"
        label="Function"
        chart-type="pie"
        wrapper-type="large f-height"
        :chart-config="audienceFunction"
      />
    </div>
    <div class="d-flex d-flex-r d-flex-between d-align-content-start section">
      <chart-wrapper
        label="Industry"
        chart-type="pie"
        wrapper-type="large f-height"
        :chart-config="audienceIndustry"
      />
      <chart-wrapper
        class="ml-1"
        label="Association"
        chart-type="pie"
        wrapper-type="large f-height"
        :chart-config="audienceAssociation"
      />
    </div>
    <div class="d-flex d-flex-r d-flex-between d-align-content-start section">
      <chart-wrapper
        label="Staff size"
        chart-type="pie"
        wrapper-type="large f-height"
        :chart-config="staffSize"
      />
      <chart-wrapper
        class="ml-1"
        label="Country"
        chart-type="pie"
        wrapper-type="large f-height"
        :chart-config="audienceCountry"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "LinkedInAudience",
  components: {
    ChartWrapper: () =>
      import("@/components/").then((res) => {
        return res.ChartWrapper;
      }),
  },
  props: {
    metrics: { type: Array, default: () => [] },
  },
  computed: {
    audienceSeniority() {
      const seniorityMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "followerCountsBySeniority",
        ) || [])[0] || {};

      const { data = [] } = seniorityMetrics;

      const seriesObj = data.reduce((acc, curr) => {
        acc[curr["count"]["seniority~"]["name"]["localized"]["en_US"]] =
          (acc[curr["count"]["seniority~"]["name"]["localized"]["en_US"]] ||
            0) + curr.count.followerCounts;
        return acc;
      }, {});

      const labels = Object.keys(seriesObj);
      const series = Object.values(seriesObj);
      const count = series.reduce((acc, cur) => acc + cur, 0);

      const chartOptions = {
        labels,

        colors: ["#0190ff", "#FFB737", "#FE6CB9", "#C7AFD3"],
      };

      return {
        series,
        count,
        chartOptions,
      };
    },
    audienceFunction() {
      const functionMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "followerCountsByFunction",
        ) || [])[0] || {};

      const { data = [] } = functionMetrics;

      const seriesObj = data.reduce((acc, curr) => {
        acc[curr["count"]["function~"]["name"]["localized"]["en_US"]] =
          (acc[curr["count"]["function~"]["name"]["localized"]["en_US"]] || 0) +
          curr.count.followerCounts;
        return acc;
      }, {});

      const labels = Object.keys(seriesObj);
      const series = Object.values(seriesObj);
      const count = series.reduce((acc, cur) => acc + cur, 0);

      const chartOptions = {
        labels,

        colors: ["#0190ff", "#FFB737", "#FE6CB9", "#C7AFD3"],
      };

      return {
        series,
        count,
        chartOptions,
      };
    },
    audienceIndustry() {
      const industryMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "followerCountsByIndustry",
        ) || [])[0] || {};

      const { data = [] } = industryMetrics;

      const seriesObj = data.reduce((acc, curr) => {
        acc[curr["count"]["industry~"]["name"]["localized"]["en_US"]] =
          (acc[curr["count"]["industry~"]["name"]["localized"]["en_US"]] || 0) +
          curr.count.followerCounts;
        return acc;
      }, {});

      const labels = Object.keys(seriesObj);
      const series = Object.values(seriesObj);
      const count = series.reduce((acc, cur) => acc + cur, 0);

      const chartOptions = {
        labels,

        colors: ["#0190ff", "#FFB737", "#FE6CB9", "#C7AFD3"],
      };

      return {
        series,
        count,
        chartOptions,
      };
    },
    audienceAssociation() {
      const associationMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "followerCountsByAssociationType",
        ) || [])[0] || {};

      const { data = [] } = associationMetrics;

      const seriesObj = data.reduce((acc, curr) => {
        acc[curr["count"]["associationType"]] =
          (acc[curr["count"]["associationType"]] || 0) +
          curr.count.followerCounts;
        return acc;
      }, {});

      const labels = Object.keys(seriesObj).map((k) => {
        const [first, ...rest] = k;
        return first + rest.join("").toLowerCase();
      });
      const series = Object.values(seriesObj);
      const count = series.reduce((acc, cur) => acc + cur, 0);

      const chartOptions = {
        labels,

        colors: ["#0190ff", "#FFB737", "#FE6CB9", "#C7AFD3"],
      };

      return {
        series,
        count,
        chartOptions,
      };
    },
    staffSize() {
      const staffSizeMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "followerCountsByStaffCountRange",
        ) || [])[0] || {};

      const { data = [] } = staffSizeMetrics;

      const seriesObj = data.reduce((acc, curr) => {
        acc[curr["count"]["staffCountRange"]] =
          (acc[curr["count"]["staffCountRange"]] || 0) +
          curr.count.followerCounts;
        return acc;
      }, {});

      const labels = Object.keys(seriesObj).map((k) => {
        const [, sizeFrom, , sizeTo] = k.split("_");
        return sizeFrom + "-" + sizeTo;
      });

      const series = Object.values(seriesObj);
      const count = series.reduce((acc, cur) => acc + cur, 0);

      const chartOptions = {
        labels,

        colors: ["#0190ff", "#FFB737", "#FE6CB9", "#C7AFD3"],
      };

      return {
        series,
        count,
        chartOptions,
      };
    },
    audienceCountry() {
      const countryMetrics =
        (this.metrics.filter(
          (metric) => metric.type === "followerCountsByGeoCountry",
        ) || [])[0] || {};

      const { data = [] } = countryMetrics;

      const seriesObj = data.reduce((acc, curr) => {
        acc[curr["count"]["geo~"]["defaultLocalizedName"]["value"]] =
          (acc[curr["count"]["geo~"]["defaultLocalizedName"]["value"]] || 0) +
          curr.count.followerCounts;
        return acc;
      }, {});

      const labels = Object.keys(seriesObj);
      const series = Object.values(seriesObj);
      const count = series.reduce((acc, cur) => acc + cur, 0);

      const chartOptions = {
        labels,

        colors: ["#0190ff", "#FFB737", "#FE6CB9", "#C7AFD3"],
      };

      return {
        series,
        count,
        chartOptions,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.section {
  > div {
    flex: 1;
  }
}
</style>
